import { FormattedMessage } from "react-intl";
import { useQuery, gql, useMutation } from '@apollo/client';
import InputGroup from "components/InputGroup";
import { useEffect, useState } from "react";
import { Loading } from "@swissvid/design-components";
import {useSession} from '@swissvid/auth-helper';

const QUERY = gql`
  query GetUser($id: ID) {
    user(id: $id) {
      name
      surname
      email
    }
  }
`;

const MUTATION = gql`
mutation Mutation($updateProfileId: ID!, $name: String!, $surname: String!, $email: String!) {
  updateProfile(id: $updateProfileId, name: $name, surname: $surname, email: $email) {
    id
    name
    surname
  }
}

`;


export default function ProfilePage() {
  const { data: { sessionUser = null } } = useSession();

  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      id: sessionUser?.id,
    },
  });

  // store the user data in state
  const [user, setUser] = useState(sessionUser);
  useEffect(() => {
    if (data) {
      setUser(data.user);
    }
  }, [data]);

  // update profile
  const [updateProfile, {
    data: updateProfileData,
    loading: updateProfileLoading,
    error: updateProfileError,
  }] = useMutation(MUTATION, {
    refetchQueries: [
      { query: QUERY },
      'GetUser'
    ],
  });

  // handle form submit
  const handleSubmit = (ev) => {
    ev.preventDefault();
    updateProfile({
      variables: {
        updateProfileId: user?.id,
        name: user.name,
        surname: user.surname,
        email: user.email,
      },
    }).then(ev => {
      console.log(ev);
    });
  };


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="flex flex-col items-center">
      <div className="w-full max-w-2xl mt-5">
        <h2 className="font-2xl font-bold">
          <FormattedMessage id="profile.page.title" />
        </h2>
        <hr className="my-5" />
        <form
          onSubmit={handleSubmit}
        >
          <InputGroup
            label={<FormattedMessage id="profile.name" />}
            // help={<FormattedMessage id="profile.nameHelp" />}
            // error={<FormattedMessage id="profile.nameError" />}
            value={user?.name}
            onChange={(ev) => setUser({ ...user, name: ev.target.value })}
          />
          <InputGroup
            label={<FormattedMessage id="profile.surname" />}
            // help={<FormattedMessage id="profile.nameHelp" />}
            // error={<FormattedMessage id="profile.nameError" />}
            value={user?.surname}
            onChange={(ev) => setUser({ ...user, surname: ev.target.value })}
          />
          <InputGroup
            label={<FormattedMessage id="profile.email" />}
            // help={<FormattedMessage id="profile.nameHelp" />}
            // error={<FormattedMessage id="profile.nameError" />}
            value={user?.email}
            onChange={(ev) => setUser({ ...user, email: ev.target.value })}
          />
          <button
            type="submit"
            className="mt-5 btn btn-primary flex justify-center items-center bg-slate-500 rounded p-2"
            disabled={updateProfileLoading}
          >
            {updateProfileLoading ?
              <Loading />
              :
              <FormattedMessage id="profile.update" />
            }
          </button>
          {!updateProfileError && updateProfileData && <p className="mt-2 text-sm text-green-600">
            <FormattedMessage id="profile.updated" />
          </p>}
        </form>
      </div>
    </div>
  );
}

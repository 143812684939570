import React, { useEffect } from 'react';
import CategoryCard from './CategoryCard';
import { useNavigate } from "react-router-dom";
import { Tab, Transition } from '@headlessui/react';
import { ChevronLeftIcon } from '@heroicons/react/24/solid'

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
  }
  return res;
}

export default function CategoryList({
  categories = [],
  activeId = null,
  isHigh = false,
  className,
}) {
  let navigate = useNavigate();
  // ref to the list wrapper
  const listRef = React.useRef(null);
  const [itemsPerRow, setItemsPerRow] = React.useState(3);
  const [rows, setRows] = React.useState(1);
  const [chunks, setChunks] = React.useState([]);
  const [height, setHeight] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [selectedIndexDirection, setSelectedIndexDirection] = React.useState(1);
  const ratio = {
    iffb: 7 / 5,
  }[process.env.REACT_APP_TAILWIND_CONFIG] || (3 / 4);

  useEffect(() => {
    const handleResize = (first = false) => {
      if (listRef.current) {
        const width = listRef.current.offsetWidth;
        const maxWidthPerItem = 200;
        const maxItemsPerRow = Math.floor(width / maxWidthPerItem);

        setHeight(width / maxItemsPerRow * ratio);
        setItemsPerRow(maxItemsPerRow);
        setRows(Math.ceil(categories.length / maxItemsPerRow));
        setChunks(sliceIntoChunks(categories, maxItemsPerRow));

        if (first) {
          const activeIndex = categories.findIndex(category => category.id === activeId);
          const activeRow = Math.floor(activeIndex / maxItemsPerRow);
          if (activeRow > 0) setSelectedIndex(activeRow);
        }
      }
    };
    handleResize(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleScroll = (direction = 'left') => {
    let offset = 0;
    if (direction === 'left') offset = -1;
    if (direction === 'right') offset = 1;

    setSelectedIndex(Math.abs((selectedIndex + offset)  % chunks.length));
    setSelectedIndexDirection(offset);
  };

  if (categories.length < 1) {
    return null;
  }

  return (
    <Tab.Group
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
      as="div"
      className={`category-list-wrapper flex flex-col w-full relative ${className} px-10 overflow-hidden`}
    >
      <Tab.Panels as="div" className="w-full relative" ref={listRef} style={{height: height}}>
        {chunks.map((chunk, index) =>
          <Transition
            key={index}
            show={index === selectedIndex}
            as="div"
            className="absolute left-0 top-0 w-full"
            enter="transition duration-500"
            enterFrom={`${selectedIndexDirection === -1 ? "-translate-x-full" : "translate-x-full"}`}
            enterTo="translate-x-0"
            leave="transition duration-500"
            leaveFrom="translate-x-0"
            leaveTo={`${selectedIndexDirection === -1 ? "translate-x-full" : "-translate-x-full"}`}
          >
            <Tab.Panel
              static={true}
              as="div"
              className="grid w-full gap-2"
              style={{
                gridTemplateColumns: `repeat(${itemsPerRow}, minmax(0, 1fr))`,
                height: `${height}px`,
              }}
            >
              {chunk.map((category, index) =>
                <CategoryCard
                  key={category.id}
                  category={category}
                  onClick={() => navigate(`/videos/${category.slug}`)}
                  active={!activeId || category.id === activeId}
                  isHigh={isHigh}
                />
              )}
            </Tab.Panel>
          </Transition>
        )}
      </Tab.Panels>

      {rows > 1 && <>
        <ScrollButton
          className="left-0"
          handleScroll={() => handleScroll('left')}
        >
          <ChevronLeftIcon className="h-5 w-5 text-white" />
        </ScrollButton>

        <ScrollButton
          className="right-0"
          handleScroll={() => handleScroll('right')}
        >
          <ChevronLeftIcon className="h-5 w-5 text-white transform rotate-180" />
        </ScrollButton>
      </>}

      <Tab.List as="div" className="category-list-pagination w-full flex justify-center items-center">
        {chunks.map((chunk, index) =>
          <Tab
            key={index}
            as="div"
            className={`category-list-pagination-item opacity-25 hover:opacity-50 ui-selected:opacity-60 flex items-center justify-center`}
          >
            <span>{index + 1}</span>
          </Tab>
        )}
      </Tab.List>
    </Tab.Group>
  );
}

function ScrollButton({
  className,
  handleScroll = () => null,
  children,
}) {
  return (
    <div
      className={`absolute top-0 h-full w-10 flex justify-center items-center ${className}`}
      onClick={handleScroll}
    >
      {children}
    </div>
  );
}
import { useEffect, useState } from "react";
import { Loading } from "@swissvid/design-components";

const zeroPad = (num, places) => String(num).padStart(places, '0');
const distanceBetweenTwoDates = (date1, date2) => {
  const distance = date1 - date2;
  return {
    days:    zeroPad(Math.floor(distance / (1000 * 60 * 60 * 24)), 2),
    hours:   zeroPad(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)), 2),
    minutes: zeroPad(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)), 2),
    seconds: zeroPad(Math.floor((distance % (1000 * 60)) / (1000)), 2)
  };
}

// countdown react component
export default function Countdown({
  countDownDate,
}) {
  const [ countdownLoading, setCountdownLoading ] = useState(true);
  const [ countDownDistances, setCountDownDistances] = useState(
    distanceBetweenTwoDates(countDownDate, new Date().getTime())
  );

  // start interval on mount
  useEffect(() => {
    // update the count down every 1 second
    const interval = setInterval(() => {
      setCountDownDistances(distanceBetweenTwoDates(countDownDate, new Date().getTime()));
      setCountdownLoading(false);
    }, 1000);
    return () => clearInterval(interval);
  }, [countDownDate]);

  return (<div className="p-10 flex flex-col justify-center items-center">
    {countdownLoading ? 
      <Loading />
      :
      <div>
        <div className="text-white text-5xl grid grid-cols-[minmax(0,2fr)_minmax(0,1fr)_minmax(0,2fr)_minmax(0,1fr)_minmax(0,2fr)_minmax(0,1fr)_minmax(0,2fr)] text-center" style={{fontFamily: "monospace"}}>
          <div>{countDownDistances.days}</div>
          <div>:</div>
          <div>{countDownDistances.hours}</div>
          <div>:</div>
          <div>{countDownDistances.minutes}</div>
          <div>:</div>
          <div>{countDownDistances.seconds}</div>
        </div>
        <div className="grid grid-cols-[minmax(0,2fr)_minmax(0,1fr)_minmax(0,2fr)_minmax(0,1fr)_minmax(0,2fr)_minmax(0,1fr)_minmax(0,2fr)] text-xs text-center">
          <div>Tage</div>
          <div></div>
          <div>Stunden</div>
          <div></div>
          <div>Minuten</div>
          <div></div>
          <div>Sekunden</div>
        </div>
      </div>
    }
  </div>);
};
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import MD5 from 'crypto-js/md5';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const call = (callback) => {
  return callback;
}

function Video ({
  videoSrc,
}) {
  const videoRef = useRef();
  const playerRef = useRef();
  const videoKey = MD5(videoSrc).toString();

  let interval;

  const options = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    liveui: true,
    playbackRates: [0.5, 1, 1.5, 2],
    sources: [{
      src: videoSrc,
      type: 'video/mp4'
    }]
  };

  const handleOnPlay = () => {
    interval = setInterval(function() {
      const player = playerRef.current;
      const currentTime = player?.currentTime();
      var lengthOfVideo = player?.duration();
      localStorage.setItem(videoKey, currentTime);
      localStorage.setItem(videoKey+'Percentage', currentTime/lengthOfVideo);
    }, 1000);
  };

  const handleOnPause = () => {
    const player = playerRef.current;
    const currentTime = player?.currentTime();
    var lengthOfVideo = player?.duration();

    clearInterval(interval);
    localStorage.setItem(videoKey, currentTime);
    localStorage.setItem(videoKey+'Percentage', currentTime/lengthOfVideo);
  };

  const handleOnSrcChange = () => {
    const player = playerRef.current;
    const currentTime = player?.currentTime();
    var lengthOfVideo = player?.duration();

    clearInterval(interval);
    localStorage.setItem(videoKey, currentTime);
    localStorage.setItem(videoKey + 'Percentage', currentTime/lengthOfVideo);
  };

  const onReady = (player) => {
    const currentPosition = localStorage.getItem(videoKey);
    player.currentTime(currentPosition || 0);

    player.on('play', call(handleOnPlay));
    player.on('pause', call(handleOnPause));
    player.on('srcChange', call(handleOnSrcChange));
  };

  const resetHandlers = (player) => {
    player.off('play');
    player.off('pause');
    player.off('srcChange');
  };

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      });
    } else {
      // you can update player here [update player through props]
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
      player.trigger('srcChange');
      resetHandlers(playerRef.current);
      onReady(playerRef.current);
    }
  }, [onReady, videoSrc]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        clearInterval(interval);
        resetHandlers(playerRef.current);
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className='video-js vjs-big-play-centered' />
    </div>
  );
}

export default Video;
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'

export default function InputGroup(
  {
    label,
    help,
    error,
    ...inputAttributes
  }
) {
  return (
    <div className="mb-5">
      <label htmlFor="email" className="block text-sm font-medium text-gray-200">
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          {...inputAttributes}
          className="p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-gray-900 bg-[#C4C4C4]"
        />
        {error && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>}
      </div>
      <p className="mt-2 text-sm text-gray-500">
        {help}
      </p>
      <p className="mt-2 text-sm text-red-600">
        {error}
      </p>
    </div>
  )
}
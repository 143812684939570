import { useQuery, gql, useMutation } from '@apollo/client';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { Loading } from "@swissvid/design-components";
// import CategoryPayment from "./CategoryPayment";
import CategoryContent from "./CategoryContent";
import CategoryInfo from "./CategoryInfo";
import CategoryList from "./CateogryList";
import {useSession} from '@swissvid/auth-helper';

const QUERY = gql`
  query GetCategoryBySlug($slug: String!, $user_id: ID) {
    categoryBySlug(slug: $slug, user_id: $user_id) {
      id
      slug
      title
      backgroundImage

      description
      alwaysShowDescription

      video
      prevideo
      coursematerial

      price
      hasAccess

      parentCategory {
        id 
        title
        slug

        video

        subcategories {
          id
          slug
          title
          backgroundImage

          video

          price
          hasAccess

          parentCategory {
            id
            title
          }

          subcategories {
            id
            video
          }
        }
      }

      subcategories {
        id
        slug
        title
        backgroundImage

        video

        price
        hasAccess

        parentCategory {
          id
          title
        }

        subcategories {
          id
          video
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation Mutation($user_id: ID!, $category_id: ID!) {
    resetBuy(user_id: $user_id, category_id: $category_id) {
      status
    }
  }
`;


export default function CategoryPage() {
  let navigate = useNavigate();
  let { categorySlug } = useParams();
  const { data: { user = null } } = useSession();
  const [ paymentPage, setPaymentPage ] = useState(false);

  useEffect(() => {
    setPaymentPage(false);
  }, [categorySlug]);

  const { loading, error, data, refetch, } = useQuery(QUERY, {
    variables: {
      slug: categorySlug || 'default',
      user_id: user?.id,
    },
    notifyOnNetworkStatusChange: true,
  });
  const category = data?.categoryBySlug;
  const [ showDescription, setShowDescription ] = useState(category?.alwaysShowDescription || false);

  const [resetBuy, {
    loading: loadingMutation,
  }] = useMutation(MUTATION);
  const handleResetBuy = () => {
    resetBuy({
      variables: {
        user_id: user?.id,
        category_id: category?.id,
      }
    }).then((event) => {
      refetch();
    });
  }

  useEffect(() => {
    setShowDescription(category?.alwaysShowDescription || false);
  }, [category]);

  if (loading) {
    return <div className="flex justify-center items-center">
      <Loading isBig onlyIcon />
    </div>;
  }
  if (error) {
    return <div className="flex justify-center items-center">
      Error: {error.message}
    </div>;
  }
  if (!category) {
    return <div className="flex justify-center items-center">
      Page Not Found!
    </div>;
  }
  return (
    <div>


      {/* {!categorySlug && <>
        <h2 className="text-xl p-3">
          Neu
        </h2>
        <CategoryList
          categories={data.getNew}
          className="w-screen flex overflow-x-scroll scrollbar-hide"
          isHigh={true}
        />
      </>} */}
      
      {(!categorySlug && process.env.REACT_APP_TAILWIND_CONFIG === 'iffb') && <>
        <div
          style={{
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundImage: `url(https://iffb.ch/file/ci/mountain.png)`,
          }}
        >
          <div
            className='bg-gradient-to-b from-transparent to-[#393939]'
            style={{
              paddingBottom: "100px",
              marginBottom: "-100px",
            }}
          >
            <div
              className='max-w-3xl mx-auto text-center py-36 flex flex-col gap-6'
            >
              <h2 className='text-4xl font-bold'>Willkommen auf der Online Akademie des Institut für Finanzbildung Schweiz</h2>
              <p className='text-lg'>Mach mit Hilfe unserer Experten den ersten Schritt in Richtung finanzielle Unabhängigkeit und starte noch heute mit unseren bewährten Finanzkursen.</p>
            </div>

          </div>
        </div>
      </>}

      {category.price > 0 && category.hasAccess && <div className="bg-gray-500 p-2 flex justify-between items-center">
          <div>Nur für die Demoversion</div>
          <button
            className="py-1 px-2 bg-white text-black text-sm rounded-md"
            onClick={handleResetBuy}
            disabled={loadingMutation}
          >
            {loadingMutation ? 
              <Loading />
              :
              <>Kauf rückgängig machen</>
            }
          </button>
      </div>}

      <div>
        <h2 className="text-xl p-3 flex justify-start items-center">
          {category.slug &&
            <button
              className="pr-3"
              onClick={() => navigate(`/videos/${category.parentCategory?.slug || ''}`)}
            >
              <ArrowLeftIcon className="h-5 w-5 text-white" />
            </button>
          }

          {!categorySlug ? 
            <>{category.title}</>
            :
            <>{category.parentCategory?.title}</>
          }
        </h2>
      </div>

      <CategoryList
        categories={category.parentCategory?.subcategories}
        activeId={category?.id}
        className="w-screen flex overflow-x-scroll scrollbar-hide"
      />

      {(!category.hasAccess && <>
        {!paymentPage && 
          <CategoryInfo
            category={category}
            setPaymentPage={setPaymentPage}
          />
        }
        {/* {paymentPage &&
          <CategoryPayment
            category={category}
            onSuccess={() => refetch({
              variables: {
                slug: categorySlug || 'default',
                user_id: user?.id,
              },
            }).then(() => setShowDescription(false))}
          />
        } */}
      </>) || <>
        <CategoryList
          categories={category.subcategories}
          className="w-screen flex overflow-x-scroll scrollbar-hide"
        />

        {showDescription ? 
          <CategoryInfo
            category={category}
            setShowDescription={setShowDescription}
          />
          :
          <CategoryContent
            category={category}
          />
        }
      </>}
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import Lightbox from 'react-datatrans-light-box'
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Loading } from '@swissvid/design-components';
import { useLocation } from "react-router-dom"

const _status_is_good = (status) => {
  return {
    "initialized": false,
    "challenge_required": false,
    "challenge_ongoing": false,
    "authenticated": false,
    "authorized": true,
    "settled": true,
    "canceled": false,
    "transmitted": true,
    "failed": false,
  }[status.toLowerCase()] || false;
};

const QUERY = gql`
  query Query($transactionId: String!) {
    transaction(transactionId: $transactionId) {
      refno
      transactionId
      amount
      currency
      status
    }
  }
`;

const MUTATION = gql`
  mutation Mutation($amount: Int!, $currency: String!, $redirectUrl: String, $user_id: String, $category_id: String) {
    createTransaction(amount: $amount, currency: $currency, redirectUrl: $redirectUrl, user_id: $user_id, category_id: $category_id) {
      transactionId
    }
  }
`;

export default function PaymentModal({
  amount = 1000,
  currency = "CHF",
  user_id,
  category_id,
}) {
  const location = useLocation();
  const [transactionId, setTransactionId] = useState(null);
  const [transactionIsWaiting, setTransactionIsWaiting] = useState(true);
  const [transactionSuccessFull, setTransactionSuccessFull] = useState(false);

  const [helper, setHelper] = useState("");



  // if the transactionId is in the url, get the transaction status
  useEffect(() => {
    console.log(location);
    const params = new URLSearchParams(location.search);
    const transactionId = params.get('datatransTrxId');

    if (transactionId) {
      setTransactionId(transactionId);
      getTransaction();
    } else {
      setTransactionId(null);
      setTransactionIsWaiting(false);
    }
  }, [location]);
  const [getTransaction, { called, loading: loadingTransaction, data: dataTransaction }] = useLazyQuery(
    QUERY,
    {
      variables: { transactionId },
      pollInterval: transactionId ? 1000 : 0,
    }
  );
  useEffect(() => {
    if (_status_is_good(dataTransaction?.transaction?.status || "")) {
      setTransactionIsWaiting(false);
      setTransactionSuccessFull(true);
      setHelper("Transaktion wurde erfolgreich abgeschlossen!");
    }
  }, [called, loadingTransaction, dataTransaction]);



  // Handle the start of a new transaction
  const [startTransaction, { loading, error, data }] = useMutation(MUTATION);
  const handleStartTransaction = () => {
    startTransaction({
      variables: {
        amount: amount,
        currency: currency,
        redirectUrl: `${process.env.REACT_APP_BASE_URL}${process.env.PUBLIC_URL}${location.pathname}`,
        user_id: `${user_id}`,
        category_id: category_id,
      },
    })
      .then(({ data }) => {
        console.log(data);
        if (data.createTransaction.transactionId) {
          showLightbox(true);
        } else {
          console.error('Error:', data);
          setHelper("Unerwarteter Fehler!")
        }
      })
      .catch((e) => {
        console.error('Error:', e, error);
        setHelper("Fehler beim Starten der Transaktion!")
      });
  }



  // helpers for the light box
  const [lightbox, showLightbox] = useState(false);
  const onLoaded = () => console.log('Loaded');
  const onOpened = () => console.log('Opened');
  const onCancelled = () => {
    showLightbox(false);
    setHelper("Transaktion wurde abgebrochen!");
  };
  const onError = (data) => {
    console.error('Error:', data);
    showLightbox(false);
    setHelper("Transaktion ist fehlgeschlagen!");
  }

  if (transactionSuccessFull) {
    return (<>
      <div>
        <button
          className={`flex justify-center items-center gap-2 bg-green-500 text-white px-4 py-2 rounded-md`}
          onClick={() => window.location.href = `${process.env.PUBLIC_URL}${location.pathname}`}
        >
          Weiter
        </button>
        {helper && <span className='text-xs'>{helper}</span>}
      </div>
    </>);
  }

  return (<>
    <div>
      <button
        className={`flex justify-center items-center gap-2 bg-green-500 text-white px-4 py-2 rounded-md ${loading || lightbox || transactionIsWaiting ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={handleStartTransaction}
      >
        {loading && <Loading />}
        {lightbox && <>Transaktion wird ausgeführt...</>}
        {transactionIsWaiting && <>Transaktion wird bearbeitet...</>}
        {(!loading && !lightbox && !transactionIsWaiting) &&
          <>für {(amount / 100).toFixed(2)} {currency} kaufen</>
        }
      </button>
      {helper && <span className='text-xs'>{helper}</span>}
    </div>

    {lightbox && <Lightbox
      transactionId={data.createTransaction.transactionId}
      production={process.env.REACT_APP_DATATRANS_PRODUCTION === 'true'}
      onLoaded={onLoaded}
      onOpened={onOpened}
      onCancelled={onCancelled}
      onError={onError}
    />}
  </>);
}
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom';
import Video from 'components/Video';
import Countdown from 'components/Countdown';
import { Markdown } from '@swissvid/design-components';

export default function CategoryContent({
  category,
  className
}) {
  let navigate = useNavigate();
  // define countdown date
  // TODO: change to actual date
  const countDownDate = new Date("2023/02/01 10:00:00").getTime();

  if (!category.video && !category.prevideo) {
    return null;
  }

  return (
    <div className={`p-3 mb-10 ${className}`}>
      <div className="flex justify-between my-5 font-bold">
        {category.title}
        <button onClick={() => navigate(`/videos/${category.parentCategory?.slug || ''}`)}>
          <XMarkIcon className="h-5 w-5 text-white" />
        </button>
      </div>

      <div className="sm:flex sm:gap-3">
        <div className="w-full sm:w-1/2 mb-3">
          {category.video && category.video.includes('youtube') ?
            <div className="overflow-hidden rounded shadow-lg">
              <iframe className="w-full aspect-video" src={category.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
            : category.video.includes('vimeo') ?
            <div className="overflow-hidden rounded shadow-lg">
              <iframe title="Vimeo Video player" className="w-full aspect-video" src={category.video} allowFullScreen></iframe>
            </div>
            :
            <div className="overflow-hidden rounded shadow-lg">
              <Video
                videoSrc={category.video}
              />
            </div>
          }
          {!category.video && 
            <div className="h-full flex flex-col justify-center items-center">
              <Countdown countDownDate={countDownDate} />
            </div>
          }
        </div>


        <div className="w-full sm:w-1/2 mb-3">
          {category.prevideo && <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>Infos</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-purple-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2">
                  <Markdown>{category.prevideo}</Markdown>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>}
          <Disclosure as="div" className="mt-2" defaultOpen>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>Material</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-purple-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2">
                  <Markdown>{category.coursematerial}</Markdown>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          {/* <Disclosure as="div" className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>Chat</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-purple-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2">
                  Coming soon...
                </Disclosure.Panel>
              </>
            )}
          </Disclosure> */}
        </div>
      </div>
    </div>
  );
}
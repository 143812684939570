import de from './de.json';
import en from './en.json';

export const DEFAULT_LANGUAGE = 'de';
export const LANGUAGES = ['de', 'en'];

const ACCEPTABLE_LANGUAGES_SET = new Set(LANGUAGES);
export function isValidLanguage(language) {
    return ACCEPTABLE_LANGUAGES_SET.has(language);
}

export function getTranslations(language) {
    switch (language) {
        case "de": return de;
        case "en": return en;
        default: return de;
    }
}
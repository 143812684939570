import { Loading, Markdown, cn } from "@swissvid/design-components";
import { useNavigate, useParams } from "react-router-dom";
import { useAsync, useTitle } from "react-use";

export default function Page() {
  const slug = useParams()["*"];
  const navigate = useNavigate();
  const { loading, error, value } = useAsync(async () => {
    if (slug === "index") navigate("/");

    let mapping;
    try {
      mapping = JSON.parse(process.env.REACT_APP_PAGE_MAPPING || "{}");
    } catch (error) {
      mapping = {};
      console.error(error);
    }
    
    const url = mapping[slug || "index"];
    if (url) {
      return fetch(url)
      .then(response => {
        console.log(response);
        if (response.ok) return response.json();
        throw new Error(response.statusText);
      });
    } else {
      const url = `${process.env.REACT_APP_STORAGE_URL}/pages/${slug || "index"}.json`;

      return fetch(url)
        .then(response => {
          console.log(response);
          if (response.ok) return response.json();
          throw new Error(response.statusText);
        })
        .then(data => {
          console.log(data);
          return {
            data
          };
        });
    }
  });
  useTitle(value?.data?.metatags?.title || (error && "Nicht gefunden!") || "Laden...");

  if (loading) {
    return <div className="flex justify-center items-center">
      <Loading isBig onlyIcon />
    </div>;
  }

  if (error) {
    console.error(error);
    return <div className="flex justify-center items-center flex-col gap-5">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
      </svg>
      <div>Leider konnte die Seite nicht gefunden werden.</div>
    </div>;
  }

  return (
    <div>
      {value?.data?.blocks?.map((block, index) => (
        <Block
          key={index}
          className={block?.class}
          bgOpacity={block?.opacity}
          bgImage={block?.image}
        >
          <Markdown>{block.markdown}</Markdown>
        </Block>
      ))}
    </div>
  );
}

function Block({
  children,
  className,
  bgImage,
  bgOpacity,
  ...props
}) {
  return (
    <div className="relative">
      <div
        className={cn(
          "absolute top-0 left-0 bottom-0 right-0",
          className === "bg-image"  && "bg-cover bg-center bg-no-repeat bg-fixed",
          className === "dark"      && "bg-dark",
          className === "light"     && "bg-white",
          className === "primary"   && "bg-primary-500",
          className === "secondary" && "bg-secondary-500",
         !className                 && "bg-white",
        )}
        style={{
          backgroundImage: className === "bg-image" && `url(${bgImage})`,
        }}
      />
      <div
        className={cn(
          "absolute top-0 left-0 bottom-0 right-0",
        )}
        style={{
          opacity: bgOpacity || 0,
          backgroundColor: "black"
        }}
      />

      <div
        className={cn(
          className,
          "p-10",
          className === "bg-image"  && "py-48",
          className === "dark"      && "text-white",
          className === "light"     && "text-dark",
          className === "primary"   && "text-white",
          className === "secondary" && "text-dark",
         !className                 && "text-dark",
        )}
        style={{
          textShadow:      className === "bg-image" && "0 2px 8px rgba(0,0,0,0.5)",
        }}
        {...props}
      >
        <div className="relative contentContainer">
          {children}
        </div>
      </div>
    </div>
  );
}
import { FormattedMessage, useIntl } from 'react-intl'
import { useEffect, useState } from 'react';
import { Loading } from "@swissvid/design-components";
import {useSession} from '@swissvid/auth-helper';

export default function LoginForm({
  onSuccess,
}) {
  const intl = useIntl();
  const { data: { session = null }, signIn } = useSession();
  
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('');
  // const [pass, setPass] = useState('');
  const [error, setError] = useState(false);
  const [info, setInfo] = useState(false);

  const handleLogin = async (ev) => {
    ev.preventDefault();
    try {
      setLoading(true);
      setError(null);
      setInfo(null);
      const { error } = await signIn({
        email,
        options: {
          emailRedirectTo: process.env.REACT_APP_BASE_URL + "/",
        },
      });
      if (error) throw error
      setInfo('Check your email for the login link!')
    } catch (error) {
      setError(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (session?.user) {
      onSuccess();
    }
  }, [session, onSuccess]);

  if (loading || session?.loading) {
    return <div className="flex justify-center items-center text-white">
      <Loading />
    </div>;
  }

  return (
    <form className="flex flex-col my-8 gap-8" onSubmit={handleLogin}>
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-300">
          <FormattedMessage id="loginform.email" />
        </label>
        <div className="mt-1">
          <input
            type="email"
            name="email"
            id="email"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-[#C4C4C4]"
            placeholder={intl.formatMessage({id: "loginform.email"})}
            aria-describedby="email-description"
            value={email}
            onChange={ev => setEmail(ev.target.value)}
          />
        </div>
      </div>

      {/* <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-300">
          <FormattedMessage id="loginform.password" />
        </label>
        <div className="mt-1">
          <input
            type="password"
            name="password"
            id="password"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-[#C4C4C4]"
            placeholder={intl.formatMessage({id: "loginform.password"})}
            aria-describedby="email-description"
            value={pass}
            onChange={ev => setPass(ev.target.value)}
          />
        </div>
      </div> */}

      <div className="flex justify-center gap-5">
        <button
          type="submit"
          className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-xl font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2"
        >
          {loading ? 'Loading...' : <FormattedMessage id="loginform.login" />}
        </button>
      </div>
      {error && <div className='text-red-500 text-center'>Anmeldung fehlgeschlagen: {error}</div>}
      {info && <div className='text-green-500 text-center'>{info}</div>}
    </form>
  );
};
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {TranslationProvider} from "./features/i18n/TranslationProvider";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import CategoryPage from 'features/category/CategoryPage';
import ProfilePage from 'features/profile/ProfilePage';
import {
  Container,
  ContentWrapper,
  Navbar,
  NavbarItems,
  Footer,
  generateTimeStringSafely,
  useNavbarItems,
} from '@swissvid/design-components';
import { RequireAuth, useSession } from '@swissvid/auth-helper';
import Page from 'features/page/Page';
import MyModal from 'components/modal/MyModal';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_BACKEND_URL,
  cache: new InMemoryCache(),
});

export default function App() {
  const { data: { user = null }, showSignIn, signOut } = useSession();
  const navbarItems = useNavbarItems();
  const navbarProps = {
    user,
    functions: {
      showSignIn,
      signOut,
    },
  };

  return (
    <TranslationProvider>
      <ApolloProvider client={client}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Container>
              {process.env.REACT_APP_LOGIN_PROVIDER === 'supabase' && <MyModal />}

              <Navbar
                left={<>
                  <NavbarItems items={navbarItems.left} {...navbarProps} />
                </>}
                right={<>
                  <NavbarItems items={navbarItems.right} {...navbarProps} />
                </>}
              />

              <ContentWrapper>
                <Routes>
                  <Route path="/profile" element={<RequireAuth><ProfilePage /></RequireAuth>} />
                  <Route path="/videos" element={<RequireAuth><CategoryPage /></RequireAuth>} />
                  <Route path="/videos/:categorySlug" element={<RequireAuth><CategoryPage /></RequireAuth>} />
                  <Route path="/*" element={<Page />} />
                </Routes>
              </ContentWrapper>

              <Footer
                left={
                  [
                    ['©', new Date().getFullYear(), process.env.REACT_APP_COPYRIGHT_NAME || 'swissvid'].join(' '),
                  ].filter(Boolean).join(' | ')
                }
                center={
                  process.env.REACT_APP_GIT_BRANCH !== 'main' && [
                    process.env.REACT_APP_GIT_BRANCH,
                    process.env.REACT_APP_GIT_COMMIT_HASH,
                    generateTimeStringSafely(process.env.REACT_APP_GIT_COMMIT_DATE),
                    'Theme: ' + process.env.REACT_APP_TAILWIND_CONFIG,
                  ].filter(Boolean).join(' | ')
                }
                right={
                  [
                    user?.email,
                  ].filter(Boolean).join(' | ')
                }
              />
            </Container>
          </BrowserRouter>
      </ApolloProvider>
    </TranslationProvider>
  );
}
import { FormattedMessage, useIntl } from 'react-intl';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';

const MUTATION = gql`
  mutation Mutation($name: String!, $surname: String!, $email: String!, $pass: String!) {
    register(name: $name, surname: $surname, email: $email, pass: $pass) {
      id
      name
      surname
      email
      password
    }
  }
`;


export default function RegisterForm({
  onSuccess,
}) {
  const intl = useIntl();
  // const [, setLoggedInUser] = useLoginState(null);
  const setLoggedInUser = () => {};
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');

  const [register, { loading, error }] = useMutation(MUTATION);

  if (loading) {
    return <div className="flex justify-center items-center">
      <div className="text-white">Loading...</div>
    </div>;
  }
  if (error) {
    console.log(error);
    return <div className="flex justify-center items-center">
      <div className="text-white">Error!</div>
    </div>;
  }

  return (
    <div className="flex flex-col my-8 gap-8">
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-300">
          <FormattedMessage id="registerform.name" />
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="name"
            id="name"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-[#C4C4C4]"
            placeholder={intl.formatMessage({id: "registerform.name"})}
            aria-describedby="email-description"
            value={name}
            onChange={ev => setName(ev.target.value)}
          />
        </div>
        {/* <p className="mt-2 text-sm text-gray-500" id="email-description">We'll only use this for spam.</p> */}
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-300">
          <FormattedMessage id="registerform.surname" />
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="surname"
            id="surname"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-[#C4C4C4]"
            placeholder={intl.formatMessage({id: "registerform.surname"})}
            aria-describedby="email-description"
            value={surname}
            onChange={ev => setSurname(ev.target.value)}
          />
        </div>
        {/* <p className="mt-2 text-sm text-gray-500" id="email-description">We'll only use this for spam.</p> */}
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-300">
          <FormattedMessage id="registerform.email" />
        </label>
        <div className="mt-1">
          <input
            type="email"
            name="email"
            id="email"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-[#C4C4C4]"
            placeholder={intl.formatMessage({id: "registerform.email"})}
            aria-describedby="email-description"
            value={email}
            onChange={ev => setEmail(ev.target.value)}
          />
        </div>
        {/* <p className="mt-2 text-sm text-gray-500" id="email-description">We'll only use this for spam.</p> */}
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-300">
          <FormattedMessage id="registerform.password" />
        </label>
        <div className="mt-1">
          <input
            type="password"
            name="password"
            id="password"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-[#C4C4C4]"
            placeholder={intl.formatMessage({id: "registerform.password"})}
            aria-describedby="email-description"
            value={pass}
            onChange={ev => setPass(ev.target.value)}
          />
        </div>
        {/* <p className="mt-2 text-sm text-gray-500" id="email-description">We'll only use this for spam.</p> */}
      </div>

      <div className="flex justify-center gap-5">
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-xl font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2"
          onClick={() => {
            register({
              variables: {
                name,
                surname,
                email,
                pass,
              },
            }).then(ev => {
              setLoggedInUser(ev.data.register?.id);
              onSuccess();
            });
          }}
        >
          <FormattedMessage id="registerform.register" />
        </button>
      </div>
    </div>
  );
};
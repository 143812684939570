import {IntlProvider} from "react-intl";
import {DEFAULT_LANGUAGE, getTranslations} from "./translations";

export function TranslationProvider({
  children
}) {
  const language = DEFAULT_LANGUAGE;

  return (
    <IntlProvider locale={`${language}-CH`} messages={getTranslations(language)}>
      {children}
    </IntlProvider>
  );
}